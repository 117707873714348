import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import ReactFlagsSelect from "react-flags-select";
import Loader from './Loader'
import { useDispatch, useSelector } from 'react-redux';
import { bookConsultationAction } from './HomeActions';
import { BOOK_CONSULTATION_RESET } from './Constants';
import moment from 'moment/moment';

const BookConsultation = ({action,service}) => {
    // if form was submitted successfully use useeffect to send bookConsult to 1
    const [lgShow, setLgShow] = useState(false);
    const [fess, setFess] = useState(true);
    const [bookConsult, setBookConsult] = useState(1)
    const dispatch= useDispatch()

    const bookConsultation = useSelector(state => state.bookConsultation)
    const {loading,error,info,success,is_greyUser} = bookConsultation

    const userLogin = useSelector(state=> state.userLogin)
    const {userInfo} = userLogin

    useEffect(()=>{
        if(fess === true){
            setLgShow(false)
            setFess(false) 
            setBookConsult(1) 
                 
        }else{
            setLgShow(true)
            setBookConsult(1)
        }
    },[action])
    const [selected, setSelected] = useState("US")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [date, setDate] = useState("")
    const [time, setTime] = useState("")
    const [message, setMessage] = useState("")
    const [services,setServices] = useState(service ? service : "")
    const [subject, setSubject] = useState("")

    const [customForm, setCustomForm] = useState(false)
    const [checkForm, setCheckForm] = useState(false)
    const meyre = React.useRef()
    useEffect(()=>{
        if (services === "custom"){
            setCustomForm(true)
        }
    },[services])
    

    const submitHandler=()=>{
        dispatch({
            type: BOOK_CONSULTATION_RESET
        })
        if(!name || !email || !phone || !date || !time || !services || !subject){
            setCheckForm(true)
        }else{
            setCheckForm(false)
            setBookConsult(bookConsult + 1)
            dispatch(bookConsultationAction({
                name,
                email,
                phone,
                date:moment(date).format('LL'),
                time,
                services,
                subject,
                message,
                selected
            }))
            setName("")
            setEmail("")
            setPhone("")
            setDate("")
            setTime("")
            setMessage("")
            setServices(service ? service : "")
            setSubject("")
        }
    }

    return (
        <div>
            <Modal
                size="lg"
                show={lgShow}
                onHide={() => {
                    setLgShow(false)
                    setCustomForm(false) 
                    setCheckForm(false)
                }}
                aria-labelledby="example-modal-sizes-title-lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                
                <div className="flex">
                    <div>
                        <img src="/Backgrounds/edit.svg" alt="." />
                    </div>
                    <div className='pl_1'>
                        <div className="pt_1 bold5">
                            Grey Valor
                        </div>
                    </div>
                </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='min_ht relative'>
                        <div className={bookConsult === 1 ? "modalBodyy" : "d_none"}>
                            <div className="activities">
                                <span className='active'></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="mdalContent">
                                <div className="bold6 font_18">
                                    Contact Details
                                </div>
                                <div className="pt_1 DForm">
                                    <input type="text" placeholder='Input Full Name'
                                        value={name}
                                        onChange={(e)=>setName(e.target.value)}
                                    />
                                    <input type="email" placeholder='Input Contact Email'
                                        value={email}
                                        onChange={(e)=>setEmail(e.target.value)}
                                    />
                                </div>
                                <div className='dispDisd'>
                                    <div className='widthSelected'>
                                        <ReactFlagsSelect
                                            searchable
                                            selected={selected}
                                            onSelect={(code) => setSelected(code)}
                                        />
                                    </div>
                                    <input type="phone" placeholder='+14659835785'
                                        value={phone}
                                        onChange={(e)=>setPhone(e.target.value)}
                                    />
                                </div>

                                
                            </div>
                        </div>
                        <div className={bookConsult === 2 ? "modalBodyy" : "d_none"}>
                            <div className="activities">
                                <span className='active'></span>
                                <span className='active'></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="mdalContent">
                                <div className="bold6 font_18">
                                    Choose your desired service
                                </div>
                                <div className="pt_1"></div>
                                <div className={customForm === false ? "selectServicesContent" : "d_none"}>
                                    <select name="services" id="services"
                                        value={service ? service : services}
                                        onChange={(e)=>setServices(e.target.value)}
                                    >
                                        <option value="select">
                                            Select Service
                                        </option>
                                        <option value="seo">
                                            SEO, SEM
                                        </option>
                                        <option value="adc">
                                            Ads campaign
                                        </option>
                                        <option value="cm">
                                            Content marketing
                                        </option>
                                        <option value="smm">
                                            Social media management
                                        </option>
                                        <option value="smn">
                                            Social media network
                                        </option>
                                        <option value="em">
                                            Email Marketing
                                        </option>
                                        <option value="cw">
                                            Copy/Content writing
                                        </option>
                                        <option value="wd">
                                            Web development
                                        </option>
                                        <option value="ma">
                                            Mobile app development
                                        </option>
                                        <option value="uid">
                                            UI/UX Designs
                                        </option>
                                        <option value="gd">
                                            Graphic Designs
                                        </option>
                                        <option value="ve">
                                            Video editing
                                        </option>
                                        <option value="va">
                                            Video ads creation
                                        </option>
                                        <option value="pm">
                                            Project Management
                                        </option>
                                        <option value="product">
                                            Product Design
                                        </option>
                                        <option value="cc">
                                            Cloud Computing
                                        </option>
                                        <option value="dm">
                                            Database Management
                                        </option>
                                        <option value="tutorship">
                                            Tutorship/Mentorship
                                        </option>

                                        <option value="custom">
                                            Make a Special Request
                                        </option>
                                        
                                    </select>
                                </div>
                                <div className={customForm === true ? "DForm" : "d_none"}>
                                    <input type="text" placeholder='Write your request'
                                        value={subject}
                                        ref={meyre}
                                        onChange={(e)=>setSubject(e.target.value)}
                                    />
                                </div>
                                <div className="DForm">
                                    <input type="text" placeholder='Subject'
                                        value={subject}
                                        onChange={(e)=>setSubject(e.target.value)}
                                    />
                                </div>
                                <div className="text-muted font_14">
                                    If this isn't what you're seeking, consider creating a <span onClick={()=>{
                                        setServices("custom")
                                        setInterval(()=>(meyre && meyre.current && meyre.current.focus()), 500)
                                    }} className='blue pointer'>custom request.</span>
                                </div>
                                
                            </div>
                        </div>
                        <div className={bookConsult === 3 ? "modalBodyy" : "d_none"}>
                            <div className="activities">
                                <span className='active'></span>
                                <span className='active'></span>
                                <span className='active'></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="mdalContent">
                                <div className="bold6 font_18">
                                    Book Consultation
                                </div>
                                <div className="pt_05 font_14 text-muted">
                                    Please let us know a convenient time for us to contact you.
                                </div>
                                <div className="pt_1 DForm">
                                    <input type="date" 
                                        value={date}
                                        onChange={(e)=>setDate(e.target.value)}
                                    />
                                    <input type="time"
                                        value={time}
                                        onChange={(e)=>setTime(e.target.value)}
                                    />
                                </div>
                                

                                
                            </div>
                        </div>
                        
                        <div className={bookConsult === 4 ? "modalBodyy" : "d_none"}>
                            <div className="activities">
                                <span className='active'></span>
                                <span className='active'></span>
                                <span className='active'></span>
                                <span className='active'></span>
                                <span></span>
                            </div>
                            <div className="mdalContent">
                                <div className="bold6 font_18">
                                    Message
                                </div>
                                <div className="pt_05 font_14 text-muted">
                                    What are your expectations from this project/service
                                </div>
                                <div className="pt_1 DForm">
                                    <textarea placeholder='Type here'
                                        value={message}
                                        onChange={(e)=>setMessage(e.target.value)}
                                    ></textarea>
                                </div>
                                

                                
                            </div>
                        </div>
                        <div className={bookConsult === 5 ? "modalBodyy" : "d_none"}>
                            <div className="activities">
                                <span className='active'></span>
                                <span className='active'></span>
                                <span className='active'></span>
                                <span className='active'></span>
                                <span className='active'></span>
                            </div>
                            <div className="mdalContent">
                                <div className="bold6 font_18">
                                    Summary
                                </div>
                                <div className="pt_05 font_14 text-muted">
                                    Here are detailed summary of your request.
                                </div>
                                <div className="pt_1 DForm">
                                    <div className="bold6 font_14 text-muted pb_05">
                                        Full Name
                                    </div>
                                    <input type="text" placeholder='Input Name'
                                        value={name}
                                        className={(checkForm && !name) ? "xdForm" : ""}
                                        onChange={(e)=>setName(e.target.value)}
                                    />
                                    <div className="bold6 font_14 text-muted pb_05">
                                        Email
                                    </div>
                                    <input type="email" placeholder='Input Email'
                                        className={(checkForm && !email) ? "xdForm" : ""}
                                        value={email}
                                        onChange={(e)=>setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="bold6 font_14 text-muted pb_05">
                                    Phone
                                </div>
                                <div className={(checkForm && !phone) ? "dispDisd xdForm" : "dispDisd"}>
                                
                                    <div className='widthSelected'>
                                        <ReactFlagsSelect
                                            searchable
                                            selected={selected}
                                            onSelect={(code) => setSelected(code)}
                                        />
                                    </div>
                                    <input type="phone" placeholder='+14659835785'
                                        value={phone}
                                        onChange={(e)=>setPhone(e.target.value)}
                                    />
                                </div>
                                

                                
                            </div>
                            <div className="mt_3">
                                <div className="border-top mx_4"></div>
                            </div>
                            <div className="pt_2"></div>
                            <div className="mdalContent">
                                <div className="bold6 font_14 text-muted pb_05">
                                    Choose your desired service
                                </div>
                                {/* <div className="pt_1"></div> */}
                                <div className={customForm === false ? "selectServicesContent" : "d_none"}>
                                    <select className={(checkForm && services ==="select") ? "xdForm" : ""} name="services" id="services"
                                        value={service ? service : services}
                                        onChange={(e)=>setServices(e.target.value)}
                                    >
                                        <option value="select">
                                            Select Service
                                        </option>
                                        <option value="seo">
                                            SEO, SEM
                                        </option>
                                        <option value="adc">
                                            Ads campaign
                                        </option>
                                        <option value="cm">
                                            Content marketing
                                        </option>
                                        <option value="smm">
                                            Social media management
                                        </option>
                                        <option value="smn">
                                            Social media network
                                        </option>
                                        <option value="em">
                                            Email Marketing
                                        </option>
                                        <option value="cw">
                                            Copy/Content writing
                                        </option>
                                        <option value="wd">
                                            Web development
                                        </option>
                                        <option value="ma">
                                            Mobile app development
                                        </option>
                                        <option value="uid">
                                            UI/UX Designs
                                        </option>
                                        <option value="gd">
                                            Graphic Designs
                                        </option>
                                        <option value="ve">
                                            Video editing
                                        </option>
                                        <option value="va">
                                            Video ads creation
                                        </option>
                                        <option value="pm">
                                            Project Management
                                        </option>
                                        <option value="product">
                                            Product Design
                                        </option>
                                        <option value="cc">
                                            Cloud Computing
                                        </option>
                                        <option value="dm">
                                            Database Management
                                        </option>
                                        <option value="tutorship">
                                            Tutorship/Mentorship
                                        </option>

                                        <option value="custom">
                                            Make a Special Request
                                        </option>
                                        
                                    </select>
                                </div>
                                <div className={customForm === true ? "DForm" : "d_none"}>
                                    <input className={(checkForm && !subject) ? "xdForm" : ""} type="text" placeholder='Write your request'
                                        value={subject}
                                        onChange={(e)=>setSubject(e.target.value)}
                                    />
                                </div>
                                <div className="DForm">
                                    <input className={(checkForm && !subject) ? "xdForm" : ""} type="text" placeholder='Subject'
                                        value={subject}
                                        onChange={(e)=>setSubject(e.target.value)}
                                    />
                                </div>
                                
                            </div>
                            <div className="mt_3">
                                <div className="border-top mx_4"></div>
                            </div>
                            <div className="mdalContent">
                                
                                <div className="DForm pt_2">
                                    <div className="bold6 font_14 text-muted pb_05">
                                        Contact Date
                                    </div>
                                    <input type="date" placeholder='Input Name'
                                        className={(checkForm && !date) ? "xdForm" : ""}
                                        value={date}
                                        onChange={(e)=>setDate(e.target.value)}
                                    />
                                    <div className="bold6 font_14 text-muted pb_05">
                                        Contact Time
                                    </div>
                                    <input type="text" placeholder='Input Email'
                                        className={(checkForm && !time) ? "xdForm" : ""}
                                        value={time}
                                        onChange={(e)=>setTime(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="mt_3">
                                <div className="border-top mx_4"></div>
                            </div>
                            <div className="mdalContent">
                                
                                <div className="DForm pt_2">
                                    <div className="bold6 font_14 text-muted pb_05">
                                        Your expectations from this project/service
                                    </div>
                                    <textarea placeholder='Type here...'
                                        value={message}
                                        onChange={(e)=>setMessage(e.target.value)}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <div className={bookConsult === 6 ? "modalBodyy" : "d_none"}>
                            <div className="activities"></div>
                            <div className="mdalContent">
                                { loading ? 
                                    <Loader />
                                :
                                    <div>
                                        {error ? 
                                            <div>
                                                <div className="bold8 text-danger center">
                                                    Request not successful.
                                                </div>
                                                <div className="pt_2 font_14">
                                                    There was an error with your request.
                                                    Please try again later.
                                                </div>
                                            </div>
                                        :
                                            <div>
                                                <div className="bold8 text-success center">
                                                    Your request was successful
                                                </div>
                                                <div className="pt_2 font_14">
                                                    Your request was received. 
                                                    You will get a response within 24hrs of 
                                                    your request.
                                                </div>
                                                
                                            </div>
                                        }

                                    </div>
                                }
                            </div>
                        </div>
                        <div className="pt_5"></div>
                        <div className={(bookConsult !== 5 && bookConsult < 6) ? "next_btnFormContainer" : "d_none"}>
                            <button className='next_btnForm' onClick={()=>{
                                setBookConsult(bookConsult + 1)
                                setCheckForm(false)
                            }}>
                                Next
                            </button>
                        </div>
                        <div className={(bookConsult === 5 && bookConsult < 6)? "next_btnFormContainer" : "d_none"}>
                            <button className='next_btnForm' onClick={()=>{
                                submitHandler()
                            }}>
                                Submit
                            </button>
                        </div>
                        <div className={(bookConsult > 1 && bookConsult < 6) ? "prev_btnFormContainer" : "d_none"}>
                            <button className='next_btnForm' onClick={()=>{
                                setBookConsult(bookConsult - 1)
                                setCheckForm(false)
                            }}>
                                Previous
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default BookConsultation